module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://kashat-api.kashat.com.eg/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kashat Instant loans","short_name":"Kashat","description":"The region's first nano financial service provider, offering small productive loans, and serving as the entry point to financial inclusion for the un- and underbanked population in Egypt.","lang":"en","display":"standalone","icon":"src/images/kashat-icon.png","start_url":"/","background_color":"#F6B904","theme_color":"#F6B904","localize":[{"start_url":"/ar/","lang":"ar","name":"كاشات تمويل رقمي سريع","short_name":"كاشات","description":"أول تطبيق للخدمات المالية في مصر يقدم تمويل متناهي الصغر لأصحاب المشاريع الصغيرة ، ويعمل على تعزيز الشمول المالي.\n\n            "}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"59221578ef0e8fe4f245782f31ea356e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
